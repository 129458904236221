import styled from 'styled-components';
import BasicSection from '../Elements/Website/BasicSection';

import Cta from '../Elements/Website/Cta';
import Features from '../Elements/Website/Features';
import FeaturesGallery from '../Elements/Website/FeaturesGallery';
import Hero from '../Elements/Website/Hero';

import Page from '../Elements/Website/Page';

import {useRouter} from 'next/router';
import {useEffect} from 'react';
import Cookies from 'js-cookie';
import {getServerSideAdminData} from 'src/Helpers/serverSidePropsHelper';

export default function Homepage() {
  const route = useRouter();
  useEffect(() => {
    if (route?.query?.ref) {
      Cookies.set('refering_company_uuid', route?.query?.ref);
    }
    console.log({route});
  }, []);
  return (
    <Page>
      <WhiteBackgroundContainer>
        <Hero />

        <BasicSection
          imageUrl={'/demo-illustration-1.svg'}
          title="Intelligent Hiring By Design"
          overTitle="Speed up your hiring">
          <p>
            As a hiring manager, you know that hiring takes a lot of time. From
            planning interviews, to screening rounds, and more. With automatic
            hiring scripts, companys now have to pick from hundreds of
            canidates. Reduce time to hire and number of interivews you conduct
            by 90% with Let's Hire hiring automation.
          </p>
        </BasicSection>
        <BasicSection
          imageUrl="/demo-illustration-2.svg"
          title="Auto-Scored Screen Rounds"
          overTitle="Automate Hiring"
          reversed>
          <p>Save time with auto-scored screening round quizes.</p>
          <ul>
            <li>20+ skills quizes to pick from</li>
            <li>Create your own quiz</li>
            <li>Have our writers make you a custom quiz</li>
          </ul>
        </BasicSection>
      </WhiteBackgroundContainer>
      <DarkerBackgroundContainer>
        <Cta />
        <FeaturesGallery />
        <Features />
      </DarkerBackgroundContainer>
    </Page>
  );
}

const HomepageWrapper = styled.div`
  & > :last-child {
    margin-bottom: 15rem;
  }
`;

const DarkerBackgroundContainer = styled.div`
  background: rgb(var(--background));

  & > *:not(:first-child) {
    margin-top: 5rem;
  }
`;

const WhiteBackgroundContainer = styled.div`
  background: rgb(var(--secondBackground));

  & > :last-child {
    padding-bottom: 15rem;
  }

  & > *:not(:first-child) {
    margin-top: 15rem;
  }
`;

export async function getServerSideProps(context) {
  // console.log('getServerSideProps', {serverProps});
  const {req, res} = context;
  const {cookies} = req;
  console.log({cookies});
  global.cookies = cookies;

  console.log('getInitialProps', {cookies});

  return {
    props: {serverCookies: cookies}, // will be passed to the page component as props
  };
}
