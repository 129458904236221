import {PropsWithChildren} from 'react';
import styled from 'styled-components';

import media from 'css-in-js-media';
import Container from './Container';
import SectionTitle from './SectionTitle';
import TopNavBar from '../../Nav/TopNavBar';
import {View, Text} from 'react-native';
import {useUserState} from '../../Providers/UserProvider';
import Footer from './Footer';

export default function Page({title, description, children, hideTop = false}) {
  const {myUserId, userSQL} = useUserState();
  const showTop = !!myUserId && !!userSQL;
  console.log('loading page:', {myUserId, userSQL, showTop});
  return (
    <View style={{height: '100vh', overflow: 'scroll'}}>
      <TopNavBar whiteHeader />
      {!!showTop && (
        <HeaderGoToAccount href="/client/jobs">
          Go To Your Account <span>&rarr;</span>
        </HeaderGoToAccount>
      )}
      <Wrapper>
        {!hideTop && title && (
          <HeaderContainer>
            <Container>
              <Title>{title}</Title>
              {description && <Description>{description}</Description>}
            </Container>
          </HeaderContainer>
        )}
        <Container>
          <ChildrenWrapper hideTop={hideTop}>{children}</ChildrenWrapper>
        </Container>
      </Wrapper>
      <Footer />
    </View>
  );
}

const Wrapper = styled.div`
  background: rgb(var(--background));
`;

const HeaderGoToAccount = styled.a`
  width: 100vw;
  padding: 5px;
  background: rgb(var(--primary));
  color: rgb(var(--textSecondary));
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  pointer: cursor;
  text-decoration: none;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(var(--secondary));
  min-height: 40rem;
`;

const Title = styled(SectionTitle)`
  color: rgb(var(--textSecondary));
  margin-bottom: 2rem;
`;

const Description = styled.div`
  font-size: 1.8rem;
  color: rgba(var(--textSecondary), 0.8);
  text-align: center;
  max-width: 60%;
  margin: auto;

  ${media('<=tablet')} {
    max-width: 100%;
  }
`;

const ChildrenWrapper = styled.div`
  ${({hideTop}) => (hideTop ? '' : 'margin-top: 10rem;')}
  margin-bottom: 10rem;
`;
