// models have to be passed in between of tree shaking issues with next js and protected variables

import moment from 'moment';

// seralizes data, like mysql dates
// if not used next js throws weird errors
const seralizeData = (data) => {
  var formattedData;
  try {
    formattedData = JSON.parse(JSON.stringify(data));
  } catch (seralizeDataError) {
    console.log({seralizeDataError});
    formattedData = [];
  }
  return formattedData;
};

const getServerSideAdminData = async (
  context,
  additionalFields,
  userModel,
  companyModel,
  jobModel = {},
  applicantModel = {},
  applicant_uuid,
) => {
  // getting cookies from context
  const {req, res} = context;
  const {cookies} = req;
  // console.log({cookies});
  global.cookies = cookies;

  // the props outputed
  // passed to components & providers as props
  const outPutProps = {
    props: {serverCookies: cookies}, // will be passed to the page component as props
  };

  // getting params
  // getting user info && company
  const userId = cookies?.userId;
  if (!userId) {
    return JSON.parse(JSON.stringify(outPutProps));
  }
  const {job_uuid, stage_uuid} = context.query;

  // getting the company info
  var userInfo = await userModel.getUserInfo(userId);
  const company = await companyModel.getCompanyInfo(userInfo?.company_uuid);
  outPutProps['props']['serverData'] = seralizeData({
    userInfo,
    company,
    requestTime: moment().toString(),
  });
  console.log({serverCompany: company, stage_uuid, applicant_uuid});

  // itereating trough and getting the data
  if (additionalFields?.includes('jobs')) {
    const myCompanyUUID = company?.uuid;
    if (!myCompanyUUID) {
      console.log('returning early no company id');
      return JSON.parse(JSON.stringify(outPutProps));
    }
    const jobs = await jobModel.getAllJobs(
      myCompanyUUID,
      userInfo?.admin_level,
    );

    const jobTemplates = await jobModel.getJobTemplates(myCompanyUUID);

    const allPromises = jobs?.map?.(async (job, index) => {
      jobs[index].description = (jobs[index].description || '').toString(
        'utf-8',
      );
      jobs[index]['stages'] = await jobModel.getAllJobStages(job.uuid);
    });
    const resolved = await Promise.all(allPromises);
    outPutProps['props']['serverData']['jobs'] = seralizeData(jobs);
    outPutProps['props']['serverData']['jobTemplates'] =
      seralizeData(jobTemplates);
  }

  if (additionalFields?.includes('stage_info')) {
    const job = await jobModel.getJobDetails(job_uuid);
    if (job) {
      job.stages = await jobModel.getAllJobStages(job_uuid);
    }

    const stageQuestions = await jobModel.getStageQuestions(stage_uuid);
    const stageDetails = await jobModel.getStageDetails(job_uuid, stage_uuid);
    const stages = await jobModel.getAllJobStages(job_uuid);
    outPutProps['props']['serverData']['job'] = seralizeData(job);
    outPutProps['props']['serverData']['stageQuestions'] =
      seralizeData(stageQuestions);
    outPutProps['props']['serverData']['stageDetails'] =
      seralizeData(stageDetails);
    outPutProps['props']['serverData']['stages'] = seralizeData(stages);
    console.log('stage_info_done');
  }

  if (additionalFields?.includes('applicants')) {
    const applicants = await applicantModel.getApplicantsForJob(job_uuid);
    outPutProps['props']['serverData']['applicants'] = seralizeData(applicants);
  }

  if (additionalFields?.includes('applicants_for_stage')) {
    const applicants = await applicantModel.getApplicantsForJob(
      job_uuid,
      stage_uuid,
    );
    outPutProps['props']['serverData']['applicants'] = seralizeData(applicants);
  }

  if (applicant_uuid) {
    const applicant = await applicantModel.getApplicant(applicant_uuid);
    outPutProps['props']['serverData']['applicant'] = seralizeData(applicant);
  }

  console.log({outPutProps});

  return outPutProps;
};

export {getServerSideAdminData};
