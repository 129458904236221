import styled from 'styled-components';
import Button from './Button';
import ButtonGroup from './ButtonGroup';
import Container from './Container';
import HeroIllustration from './HeroIllustation';
import OverTitle from './OverTitle';
// import {useNewsletterModalContext} from 'contexts/newsletter-modal.context';
import media from 'css-in-js-media';
import {useUserState} from 'src/Providers/UserProvider';
import {useModalState} from 'src/Providers/ModalProvider';
import {useRouter} from 'next/router';

export default function Hero() {
  const {setLoginModalOpen} = useModalState();
  const {myUserId} = useUserState();
  const router = useRouter();

  const handleJoinPress = () => {
    if (!myUserId) {
      setLoginModalOpen(true);
    } else {
      router.push('/client/jobs');
    }
  };

  return (
    <HeroWrapper>
      <Contents>
        <CustomOverTitle>
          the easiest, applicant tracking system you will ever see
        </CustomOverTitle>
        <Heading>Accelerate Your Hiring With Let's Hire</Heading>
        <Description>
          Let's Hire has more features than any other applicant tracking system
          at one one-hundreth of the cost. Let's Hire allows for your company to
          have custom interviewing and onboarding. Do it all: Screening Quizes,
          automatic screening, pre-record on-demand interviews, expert written
          interview questions, and more.
        </Description>
        <CustomButtonGroup>
          <Button onClick={() => handleJoinPress()}>
            {!!myUserId ? 'Go To Account' : 'Sign Up Today'} <span>&rarr;</span>
          </Button>

          <Button transparent href="/features">
            Features <span>&rarr;</span>
          </Button>
        </CustomButtonGroup>
      </Contents>
      <ImageContainer>
        <HeroIllustration />
      </ImageContainer>
    </HeroWrapper>
  );
}

const HeroWrapper = styled(Container)`
  display: flex;
  padding-top: 5rem;

  ${media('<=desktop')} {
    padding-top: 1rem;
    flex-direction: column;
    align-items: center;
  }
`;

const Contents = styled.div`
  flex: 1;
  max-width: 60rem;

  ${media('<=desktop')} {
    max-width: 100%;
  }
`;

const CustomButtonGroup = styled(ButtonGroup)`
  margin-top: 4rem;
`;

const ImageContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-start;

  svg {
    max-width: 45rem;
  }

  ${media('<=desktop')} {
    margin-top: 2rem;
    justify-content: center;
    svg {
      max-width: 80%;
    }
  }
`;

const Description = styled.p`
  font-size: 1.8rem;
  opacity: 0.8;
  line-height: 1.6;

  ${media('<=desktop')} {
    font-size: 1.5rem;
  }
`;

const CustomOverTitle = styled(OverTitle)`
  margin-bottom: 2rem;
`;

const Heading = styled.h1`
  font-size: 7.2rem;
  font-weight: bold;
  line-height: 1.1;
  margin-bottom: 4rem;
  letter-spacing: -0.03em;

  ${media('<=tablet')} {
    font-size: 4.6rem;
    margin-bottom: 2rem;
  }
`;
