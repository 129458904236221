import React from 'react';
import styled from 'styled-components';
import Button from './Button';
import ButtonGroup from './ButtonGroup';
import Container from './Container';
import OverTitle from './OverTitle';
import SectionTitle from './SectionTitle';
import media from 'css-in-js-media';

export default function Cta() {
  return (
    <CtaWrapper>
      <Container>
        <Stack>
          <OverTitle>Post Your First Job For Free</OverTitle>
          <SectionTitle>With Let's Hire Your First Job Is Free!</SectionTitle>
          <Description>
            Whether you want to test us out or are growing your business with
            your first job post, Let's Roam is here to help.
          </Description>
          <ButtonGroup>
            <Button href="/sign-up">
              Post your first free job <span>&rarr;</span>
            </Button>

            <OutlinedButton transparent href="/features">
              Features <span>&rarr;</span>
            </OutlinedButton>
          </ButtonGroup>
        </Stack>
      </Container>
    </CtaWrapper>
  );
}

const Description = styled.div`
  font-size: 1.8rem;
  color: rgba(var(--textSecondary), 0.8);
`;

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12.5rem 0;
  color: rgb(var(--textSecondary));
  text-align: center;
  align-items: center;
  justify-content: center;

  & > *:not(:first-child) {
    max-width: 80%;
    margin-top: 4rem;
  }

  ${media('<=tablet')} {
    text-align: center;

    & > *:not(:first-child) {
      max-width: 100%;
      margin-top: 2rem;
    }
  }
`;

const OutlinedButton = styled(Button)`
  border: 1px solid rgb(var(--textSecondary));
  color: rgb(var(--textSecondary));
  height: 40px;
`;

const CtaWrapper = styled.div`
  background: rgb(var(--secondary));
`;
