import React from 'react';
import styled from 'styled-components';
import AutofitGrid from './AutofitGrid';
import BasicCard from './BasicCard';
import Container from './Container';
import media from 'css-in-js-media';

const FEATURES = [
  {
    imageUrl: '/grid-icons/asset-3.svg',
    title: 'Simplified Hiring Dashboards',
    description: `Let's Hire has enterpise grade software to simplify your companies hiring needs.`,
  },
  {
    imageUrl: '/grid-icons/asset-4.svg',
    title: 'Customized Interview Templates',
    description:
      'Customize your companies hiring process with easy your own templates or use ours.',
  },

  {
    imageUrl: '/grid-icons/asset-5.svg',
    title: 'Secure Data Storage',
    description: 'Your data is secured with enterpise grade encyption.',
  },

  {
    imageUrl: '/grid-icons/asset-8.svg',
    title: 'Streamlined Hiring Flows',
    description:
      'Speed up the hiring process with on demand video interviews, graded quizes, and more.',
  },
  {
    imageUrl: '/grid-icons/asset-9.svg',
    title: 'Hire anywhere',
    description: 'Post your job on existing channels.',
  },
];

export default function Features() {
  return (
    <Container>
      <CustomAutofitGrid>
        {FEATURES.map?.((singleFeature, idx) => (
          <BasicCard key={singleFeature.title} {...singleFeature} />
        ))}
      </CustomAutofitGrid>
    </Container>
  );
}

const CustomAutofitGrid = styled(AutofitGrid)`
  --autofit-grid-item-size: 40rem;

  ${media('<=tablet')} {
    --autofit-grid-item-size: 30rem;
  }

  ${media('<=phone')} {
    --autofit-grid-item-size: 100%;
  }
`;
