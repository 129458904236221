import {FacebookIcon, LinkedinIcon, TwitterIcon} from 'react-share';
import styled from 'styled-components';
import Container from './Container';
import media from 'css-in-js-media';

const footerItems = [
  {
    title: 'About',
    items: [
      {title: 'Home', href: '/'},
      {title: 'Contact', href: '/contact'},
    ],
  },
  {
    title: 'Product',
    items: [
      {title: 'Features', href: '/features/'},
      {title: 'Pricing', href: '/pricing/'},
      {title: 'Sign Up', href: '/sign-up/'},
    ],
  },
];

export default function Footer() {
  return (
    <FooterWrapper>
      <Container>
        <ListContainer>
          {footerItems.map?.((singleItem) => (
            <FooterList
              key={singleItem.title}
              title={singleItem.title}
              items={singleItem.items}
            />
          ))}
          <div></div>
          <div></div>
        </ListContainer>
        <BottomBar>
          <Copyright>&copy; Copyright 2022 Let's Hire</Copyright>
        </BottomBar>
      </Container>
    </FooterWrapper>
  );
}

function FooterList({title, items}) {
  return (
    <ListWrapper>
      <ListHeader>{title}</ListHeader>
      {items.map?.((singleItem) => (
        <ListItemElement
          key={singleItem.href}
          title={singleItem.title}
          href={singleItem.href}
        />
      ))}
    </ListWrapper>
  );
}

function ListItemElement({title, href}) {
  console.log('ListItemElement', {href});
  return (
    <a
      href={href}
      style={{
        fontSize: 14,
        textDecoration: 'none',
        color: 'white',
      }}>
      {title}
    </a>
  );
}

const FooterWrapper = styled.div`
  padding-top: 10rem;
  padding-bottom: 4rem;
  background: rgb(var(--secondary));
  color: rgb(var(--textSecondary));
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ListHeader = styled.p`
  font-weight: bold;
  font-size: 2.25rem;
  margin-bottom: 2.5rem;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
  margin-right: 5rem;

  & > *:not(:first-child) {
    margin-top: 1rem;
  }

  ${media('<=tablet')} {
    flex: 0 40%;
    margin-right: 1.5rem;
  }

  ${media('<=phone')} {
    flex: 0 100%;
    margin-right: 0rem;
  }
`;

const ShareBar = styled.div`
  & > *:not(:first-child) {
    margin-left: 1rem;
  }
`;

const Copyright = styled.p`
  font-size: 1.5rem;
  margin-top: 0.5rem;
`;

const BottomBar = styled.div`
  margin-top: 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media('<=tablet')} {
    flex-direction: column;
  }
`;
